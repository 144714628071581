import React from "react"

export default function Tos() {
    const __html = require('./html/Tos.html');
    const template = { __html: __html };

    return (
        <div className="container-fluid">
            <div dangerouslySetInnerHTML={template} />
        </div>
    )
}
