import React, { Component } from "react"
import TosCmp from "../components/Description/Tos/Tos"
import Layout from "../components/Layout"

export default function Tos() {
    return (
        <Layout>
            <TosCmp />
        </Layout>
    )
}
